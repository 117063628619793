import {checkValid} from "./functions/ModelsValidate";

export function Delivery() {

  this.checkValid = checkValid

  this.delivery = {
    address: '',
    address2: '',
    city: '',
    region: '',
    country: '',
    zip: '',
  }

  this.validation = {
    address: false,
    address2: false,
    city: false,
    region: false,
    country: false,
    zip: false,
  }

  this.validationTranslate = {
    address: '',
    address2: '',
    city: '',
    region: '',
    country: '',
    zip: '',
  }

  this.validationTxt = {
    address: false,
    address2: false,
    city: false,
    region: false,
    country: false,
    zip: false,
  }

}


/*****************   SETTERS   ******************/
Delivery.prototype.setAddress = function (val) {
  this.delivery.address = val;
}

Delivery.prototype.setAddress2 = function (val) {
  this.delivery.address2 = val;
}

Delivery.prototype.setCity = function (val) {
  this.delivery.city = val;
}

Delivery.prototype.setRegion = function (val) {
  this.delivery.region = val;
}

Delivery.prototype.setCountry = function (val) {
  this.delivery.country = val;
}

Delivery.prototype.setZip = function (val) {
  this.delivery.zip = val;
}

Delivery.prototype.setDeliveryItem = function (val) {
  this.delivery.address = val.address ? val.address : ''
  this.delivery.address2 = val.address2 ? val.address2 : ''
  this.delivery.city = val.city ? val.city : ''
  this.delivery.region = val.region ? val.region : ''
  this.delivery.country = val.country ? val.country : ''
  this.delivery.zip = val.zip ? val.zip : ''
}


/*****************   GETTERS   ******************/
Delivery.prototype.getAddress = function () {
  return this.delivery.address;
}

Delivery.prototype.getAddress2 = function () {
  return this.delivery.address2;
}

Delivery.prototype.getCity = function () {
  return this.delivery.city;
}

Delivery.prototype.getRegion = function () {
  return this.delivery.region;
}

Delivery.prototype.getCountry = function () {
  return this.delivery.country;
}

Delivery.prototype.getZip = function () {
  return this.delivery.zip;
}


/*****************   VALIDATION   ******************/

Delivery.prototype.deliveryInfoValidate = function () {

  let validationItems = {
    address: this.delivery.address,
    address2: this.delivery.address2,
    city: this.delivery.city,
    region: this.delivery.region,
    country: this.delivery.country,
    zip: this.delivery.zip,
  }

  let validationOptions = {
    address: {type: ['latin', 'empty']},
    address2: {type: ['latin']},
    city: {type: ['latin', 'empty']},
    region: {type: ['latin', 'empty']},
    country: {type: ['empty']},
    zip: {type: ['empty']},
  }


  return this.checkValid(validationItems, validationOptions)
}


Delivery.prototype.deliveryInfoPartialValidate = function (
    {
      address,
      address2,
      city,
      region,
      country,
      zip,
      latin,
      onlyLatinAddress
    }
) {

  let validationItems = {
    address: this.delivery.address,
    address2: this.delivery.address2,
    city: this.delivery.city,
    region: this.delivery.region,
    country: this.delivery.country,
    zip: this.delivery.zip,
  }

  let validationOptions = {
    address: {type: address ? ['latin', 'empty'] : []},
    address2: {type: address2 ? ['latin'] : []},
    city: {type: city ? ['latin', 'empty'] : []},
    region: {type: region ? ['latin', 'empty'] : []},
    country: {type: country ? ['empty'] : []},
    zip: {type: zip ? ['empty'] : []},
  }

  if(latin){
    validationOptions.address.type.push('latin')
    validationOptions.city.type.push('latin')
    validationOptions.region.type.push('latin')
    validationOptions.zip.type.push('latin')
  }

  if(onlyLatinAddress) {
    validationOptions.address.type.push('latinAddress')
    validationOptions.address2.type.push('latinAddress')
  }

  return this.checkValid(validationItems, validationOptions)
}

Delivery.prototype.deliveryAddressExcludedValidate = function () {

  if (
    this.delivery.address.toLowerCase().indexOf('po box') > -1 ||
    this.delivery.address.toLowerCase().indexOf('apo') > -1 ||
    this.delivery.address.toLowerCase().indexOf('fpo') > -1 ||
      (this.delivery.address2.length > 0 && this.delivery.address2.toLowerCase().indexOf('po box') > -1) ||
      (this.delivery.address2.length > 0 && this.delivery.address2.toLowerCase().indexOf('apo') > -1) ||
      (this.delivery.address2.length > 0 && this.delivery.address2.toLowerCase().indexOf('fpo') > -1)
    // this.delivery.address2.toLowerCase().indexOf('apo') > -1 ||
    // this.delivery.address2.toLowerCase().indexOf('fpo') > -1
  ) {
    this.validation.address = true
    this.validation.address2 = true
    this.validationTranslate.address = 'error_TNTAddress'
    this.validationTranslate.address2 = 'error_TNTAddress'
    return false
  } else {
    this.validation.address = false
    this.validation.address2 = false
    this.validationTranslate.address = ''
    this.validationTranslate.address2 = ''
    return true
  }

}

Delivery.prototype.deliveryPrepare = function () {
  return {
    address: this.getAddress(),
    address2: this.getAddress2(),
    city: this.getCity(),
    region: this.getRegion(),
    country: this.getCountry(),
    zip: this.getZip(),
  }
}
