<template>
  <modal
      :canBeClosedByUser="canBeClosedByUser"
      @close="$emit('close')"
      class="custom-popup-modal statistic-popup"
  >
    <template slot="header">
      <div
          v-if="titleText !== ''"
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([titleText,])"></div>
        {{ $t(`${titleText}.localization_value.value`) }}
      </div>
      <div
          v-else
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['dashboard_orderSummary',])"></div>
         {{ $t('dashboard_orderSummary.localization_value.value') }}
      </div>

    </template>
    <template slot="body">
      <div class="custom-popup__content">
           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<span class="admin-edit"-->
              <!--@click="editTranslate([-->
              <!--'dashboard_orderAmount',-->
              <!--'dashboard_paySystemCommission',-->
              <!--'dashboard_userBalance',-->
              <!--'dashboard_amountToPay',-->
              <!--'dashboard_cardDetails',-->
              <!--'common_month',-->
              <!--'common_year',-->
              <!--'common_CVV',-->
              <!--'common_buildingAddress',-->
              <!--'common_name',-->
              <!--'common_surName',-->
              <!--'common_phone',-->
              <!--'common_street',-->
              <!--'common_city',-->
              <!--'common_state',-->
              <!--'common_zipCode',-->
              <!--'common_cancel',-->
              <!--'common_payByCard',-->
              <!--])"></span>-->


        <template v-if="consolidationType">
          <template
              v-if="consolidationData && _.has(consolidationData, 'totals') && consolidationData.totals.orders.length > 0">
            <div class="statistic-popup__price mb-2"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"
                 v-for="(order, index) in consolidationData.totals.orders"
                 :key="index"
            >
              <div class="admin-edit" @click="editTranslate(['consolidation_OrdersAmount',])"></div>
              <b>{{ $t('consolidation_OrdersAmount.localization_value.value') }} : #{{order.id}} : ${{ order.delivery_cost }}</b>
            </div>
          </template>

          <div class="statistic-popup__price mb-2"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"
               v-if="consolidationData && _.has(consolidationData, 'totals')"
          >
            <div class="admin-edit" @click="editTranslate(['consolidation_consolidationShipment',])"></div>
            <b>{{ $t('consolidation_consolidationShipment.localization_value.value') }} : #{{consolidationData.totals.consolidation.id}} : ${{ consolidationData.totals.consolidation.order_amount }}</b>
          </div>

          <div class="statistic-popup__price mb-2"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"
               v-if="consolidationData && _.has(consolidationData, 'totals')"
          >
            <div class="admin-edit" @click="editTranslate(['consolidation_consolidationShipment',])"></div>
            <b>{{ $t('consolidations_vatCost.localization_value.value') }} : #{{consolidationData.totals.consolidation.id}} : ${{ consolidationData.totals.consolidation.vat_amount }}</b>
          </div>

          <div class="statistic-popup__price mb-2"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"
               v-if="consolidationData && _.has(consolidationData, 'payment_system_commission')"
          >
            <div class="admin-edit" @click="editTranslate(['consolidation_AuthorizeCommission',])"></div>
            <b>{{ $t('consolidation_AuthorizeCommission.localization_value.value') }}: ${{ consolidationData.payment_system_commission }}</b>
          </div>

          <div class="statistic-popup__price mb-2"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"
               v-if="consolidationData && _.has(consolidationData, 'user_balance')"
          >
            <div class="admin-edit" @click="editTranslate(['consolidation_ConsolidationShippingCostTotal',])"></div>
            <b>{{ $t('consolidation_UserBalance.localization_value.value') }}: ${{ consolidationData.user_balance }}</b>
          </div>
          <br>

        </template>


        <div class="statistic-popup__price mb-2"
             v-if="authorizePayData && _.has(authorizePayData, 'orderAmount') && authorizePayData.orderAmount"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['dashboard_orderAmount',])"></div>
          <b>{{ $t('dashboard_orderAmount.localization_value.value') }}: ${{ authorizePayData.orderAmount }}</b>
        </div>
        <div class="statistic-popup__price mb-2"
             v-if="authorizePayData && _.has(authorizePayData, 'paySystemCommission') && authorizePayData.paySystemCommission"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['dashboard_paySystemCommission',])"></div>
          <b>{{ $t('dashboard_paySystemCommission.localization_value.value') }}: ${{ authorizePayData.paySystemCommission }}</b>
        </div>
        <div class="statistic-popup__price mb-2"
             v-if="authorizePayData && _.has(authorizePayData, 'userBalance') && authorizePayData.userBalance"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['dashboard_userBalance',])"></div>
          <b>{{ $t('dashboard_userBalance.localization_value.value') }}: ${{ authorizePayData.userBalance }}</b>
        </div>


        <div class="statistic-popup__price"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['dashboard_amountToPay',])"></div>
          <b>{{ $t('dashboard_amountToPay.localization_value.value') }}: ${{ countMoney }}</b>
        </div>
        <div class="block-table">
          <div class="section-label"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['dashboard_cardDetails',])"></div>
            {{ $t('dashboard_cardDetails.localization_value.value') }}
          </div>
          <div class="block-table__row">
            <div class="block-table__col"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['dashboard_card',])"></div>
              <DefaultInput
                  :label="$t('dashboard_card.localization_value.value')"
                  v-bind:class="{'ui-no-valid': validation.card || checkCredit}"
                  :error="validation.card || checkCredit"
                  :errorTxt="serverError ? validationTxt.card : $t(`${validationTranslate.card}.localization_value.value`)"
                  :type="'text'"
                  :autocomplete="'cc-number'"
                  :autocompletetype="'cc-number'"
                  :name="'cc-number'"
                  v-model="card"
                  @blur="checkCard"
                  :maxLength="'16'"
              />
            </div>

          </div>
          <div class="block-table__row">
            <div class="block-table__col block-table__col--25"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_month',])"></div>
              <DefaultInput
                  :label="$t('common_month.localization_value.value')"
                  v-bind:class="{'ui-no-valid': validation.month}"
                  :error="validation.month"
                  :errorTxt="serverError ? validationTxt.month : $t(`${validationTranslate.month}.localization_value.value`)"
                  :type="'text'"
                  v-model="month"
                  :maxLength="'2'"
                  :autocomplete="'cc-exp-month'"
                  :autocompletetype="'cc-exp-month'"
                  :name="'cc-exp-month'"
                  @input="changeMonth"
              />
            </div>
            <div class="block-table__col block-table__col--25"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_year',])"></div>
              <DefaultInput
                  :label="$t('common_year.localization_value.value')"
                  v-bind:class="{'ui-no-valid': validation.year}"
                  :error="validation.year"
                  :errorTxt="serverError ? validationTxt.year : $t(`${validationTranslate.year}.localization_value.value`)"
                  :type="'text'"
                  v-model="year"
                  :maxLength="'2'"
                  :autocomplete="'cc-exp-year'"
                  :autocompletetype="'cc-exp-year'"
                  :name="'cc-exp-year'"
                  ref="refYear"
                  @input="changeYear"
              />
            </div>
            <div class="block-table__col block-table__col--50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_CVV',])"></div>
              <DefaultInput
                  :label="$t('common_CVV.localization_value.value')"
                  v-bind:class="{'ui-no-valid': validation.cardCode}"
                  :error="validation.cardCode"
                  :errorTxt="serverError ? validationTxt.cardCode : $t(`${validationTranslate.cardCode}.localization_value.value`)"
                  :type="'text'"
                  :textSecurity="true"
                  :autocomplete="'cc-csc'"
                  :name="'cvv2'"
                  v-model="cardCode"
                  :maxLength="'3'"
                  ref="refCVV"
              />
            </div>
          </div>

          <div class="section-label"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_buildingAddress',])"></div>
            {{ $t('common_buildingAddress.localization_value.value') }}
          </div>

          <div class="block-table__row">
            <div class="block-table__col block-table__col--50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_name',])"></div>
              <DefaultInput
                  :label="$t('common_name.localization_value.value')"
                  v-bind:class="{'ui-no-valid': validation.firstName}"
                  :error="validation.firstName"
                  :errorTxt="serverError ? validationTxt.firstName : $t(`${validationTranslate.firstName}.localization_value.value`)"
                  :type="'text'"
                  :autocomplete="'cc-first-name'"
                  :autocompletetype="'cc-first-name'"
                  :name="'cc-first-name'"
                  v-model="firstName"
              />
            </div>
            <div class="block-table__col block-table__col--50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_surName',])"></div>
              <DefaultInput
                  :label="$t('common_surName.localization_value.value')"
                  v-bind:class="{'ui-no-valid': validation.lastName}"
                  :error="validation.lastName"
                  :errorTxt="serverError ? validationTxt.lastName : $t(`${validationTranslate.lastName}.localization_value.value`)"
                  :type="'text'"
                  :autocomplete="'cc-last-name'"
                  :autocompletetype="'cc-last-name'"
                  :name="'cc-last-name'"
                  v-model="lastName"
              />
            </div>
          </div>
          <div class="block-table__row">
            <div class="block-table__col block-table__col--50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_phone',])"></div>
              <DefaultInput
                  :label="$t('common_phone.localization_value.value')"
                  v-bind:class="{'ui-no-valid': validation.phone}"
                  :error="validation.phone"
                  :errorTxt="serverError ? validationTxt.phone : $t(`${validationTranslate.phone}.localization_value.value`)"
                  :type="'tel'"
                  :autocomplete="'cc-phone'"
                  :autocompletetype="'cc-phone'"
                  :name="'cc-phone'"
                  v-model="phone"
              />
            </div>
            <div class="block-table__col block-table__col--50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_country',])"></div>
              <!--<DefaultInput-->
              <!--:label="'Shipping Country'"-->
              <!--v-bind:class="{'ui-no-valid': validation.selectedCountry}"-->
              <!--:error="validation.selectedCountry"-->
              <!--:errorTxt="serverError ? validationTxt.selectedCountry : $t(`${validationTranslate.selectedCountry}.localization_value.value`)"-->
              <!--:type="'text'"-->
              <!--v-model="selectedCountry"-->
              <!--/>-->
              <DefaultSelect
                  v-bind:class="{'ui-no-valid': validation.selectedCountry}"
                  :error="validation.selectedCountry"
                  :errorTxt="serverError ? validationTxt.selectedCountry : $t(`${validationTranslate.selectedCountry}.localization_value.value`)"
                  :options="options"
                  @change="changeCountry"
                  :selected="selectedCountry.label"
                  :label="$t('common_country.localization_value.value')"
                  :optionsLabel="'label'"
              />
            </div>
          </div>
          <div class="block-table__row">
            <div class="block-table__col block-table__col--50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_street',])"></div>
              <DefaultInput
                  :label="$t('common_street.localization_value.value')"
                  v-bind:class="{'ui-no-valid': validation.street}"
                  :error="validation.street"
                  :errorTxt="serverError ? validationTxt.street : $t(`${validationTranslate.street}.localization_value.value`)"
                  :type="'text'"
                  :autocomplete="'cc-street'"
                  :autocompletetype="'cc-street'"
                  :name="'cc-street'"
                  v-model="street"
              />
            </div>
            <div class="block-table__col block-table__col--50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_city',])"></div>
              <DefaultInput
                  :label="$t('common_city.localization_value.value')"
                  v-bind:class="{'ui-no-valid': validation.city}"
                  :error="validation.city"
                  :errorTxt="serverError ? validationTxt.city : $t(`${validationTranslate.city}.localization_value.value`)"
                  :type="'text'"
                  :autocomplete="'cc-city'"
                  :autocompletetype="'cc-city'"
                  :name="'cc-city'"
                  v-model="city"
              />
            </div>
          </div>
          <div class="block-table__row">
            <div class="block-table__col block-table__col--50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_state',])"></div>
              <DefaultSelect
                  v-if="selectedCountryObject && selectedCountryObject.id === USA_COUNTRY_ID && regions.length > 0"
                  :options="regions"
                  :label="$t('common_state.localization_value.value')"
                  v-bind:class="{'ui-no-valid': validation.state}"
                  :errorTxt="$t(`${validationTranslate.state}.localization_value.value`)"
                  :error="validation.state"
                  :optionsLabel="'code'"
                  :selected="state.code"
                  @change="val => {changeRegion(val)}"
              />
              <DefaultInput
                  v-else
                  :label="$t('common_state.localization_value.value')"
                  v-bind:class="{'ui-no-valid': validation.state}"
                  :error="validation.state"
                  :errorTxt="serverError ? validationTxt.state : $t(`${validationTranslate.state}.localization_value.value`)"
                  :type="'text'"
                  :autocomplete="'cc-state'"
                  :autocompletetype="'cc-state'"
                  :name="'cc-state'"
                  v-model="state"
              />
            </div>
            <div class="block-table__col block-table__col--25"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_zipCode',])"></div>
              <DefaultInput
                  :label="$t('common_zipCode.localization_value.value')"
                  v-bind:class="{'ui-no-valid': validation.zipCode}"
                  :error="validation.zipCode"
                  :errorTxt="serverError ? validationTxt.zipCode : $t(`${validationTranslate.zipCode}.localization_value.value`)"
                  :type="'text'"
                  :autocomplete="'cc-zipCode'"
                  :autocompletetype="'cc-zipCode'"
                  :name="'cc-zipCode'"
                  v-model="zipCode"
              />
            </div>
          </div>

          <div class="ui-no-valid-card" v-if="validation.error">
            {{ validationTxt.error }}
          </div>
        </div>
      </div>
    </template>

    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center mt-5"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_SaveInDrafts','common_cancel', 'common_payByCard'])"></div>
				<span
            v-if="canBeClosedByUser !== false"
            @click="$emit('close')"
            class="site-link site-link--alt mr-4"
        >
<!--          <template v-if="changeCloseToSaveBtn">-->
<!--            {{$t('common_SaveInDrafts.localization_value.value')}}-->
<!--          </template>-->
<!--          <template v-else>-->
					  {{ $t('common_cancel.localization_value.value') }}
<!--          </template>-->
				</span>

        <MainButton
            :value="$t('common_payByCard.localization_value.value')"
            @click.native="applyForm"
            v-bind:class="{'disabled-btn' : $store.getters.getAuthorizeBtn}"
        />

      </div>
    </template>

  </modal>
</template>

<script>
import Modal from "../../../commonModals/Modal";
import DefaultInput from "../../../UI/inputs/DefaultInput/DefaultInput";
import MainButton from "../../../UI/buttons/MainButton/MainButton";
import {validation} from "../../../../services/validation";
import DefaultSelect from "../../../UI/selectiones/DefaultSelect/DefaultSelect";
import {isValid} from '../../../../../node_modules/creditcard.js/dist/creditcard';
import {filterCountriesUaBel} from "../../../../services/filterCountries";
import {USA_COUNTRY_ID} from "../../../../staticData/staticVariables";

export default {
  name: "AuthorizePaymentPopup",
  components: {
    Modal,
    DefaultInput,
    MainButton,
    DefaultSelect,
  },

  props: {
    serverError: {},
    countMoney: {},
    authorizePayData: {},
    authorizeError: {},
    changeCloseToSaveBtn: {
      type: Boolean,
      default: false
    },
    canBeClosedByUser: {
      type: Boolean,
      default: true,
    },
    titleText: {
      type: String,
      default: '',
    },
    consolidationType: {
      type: Boolean,
      default: false,
    },
    consolidationData: Object,
  },

  // props: [
  //   'serverError',
  //   'countMoney',
  //   'authorizePayData',
  //   'authorizeError',
  //   'changeCloseToSaveBtn',
  //   'canBeClosedByUser',
  // ],


  data() {
    return {
      USA_COUNTRY_ID: USA_COUNTRY_ID,

      card: '',
      month: '',
      year: '',
      cardCode: '',
      firstName: '',
      lastName: '',
      phone: '',
      selectedCountry: '',
      selectedCountryObject: null,
      city: '',
      street: '',
      state: '',
      zipCode: '',

      localServerError: false,

      options: [],
      regions: [],
      checkCredit: false,


      validation: {
        card: false,
        month: false,
        year: false,
        cardCode: false,
        firstName: false,
        lastName: false,
        phone: false,
        selectedCountry: false,
        city: false,
        street: false,
        state: false,
        zipCode: false,
        error: false,
      },

      validationTranslate: {
        card: 'default',
        month: 'default',
        year: 'default',
        cardCode: 'default',
        firstName: 'default',
        lastName: 'default',
        phone: 'default',
        selectedCountry: 'default',
        city: 'default',
        street: 'default',
        state: 'default',
        zipCode: 'default',
        error: 'default',
      },

      validationTxt: {
        card: false,
        month: false,
        year: false,
        cardCode: false,
        firstName: false,
        lastName: false,
        phone: false,
        selectedCountry: false,
        city: false,
        street: false,
        state: false,
        zipCode: false,
        error: false,
      },

    }
  },


  watch: {
    serverError: (newVal) => {
      console.log(newVal);
      // this.localServerError = newVal
    },
    authorizeError: function (errors) {
      errors['card.number'] ? (this.validation.card = true, this.validationTxt.card = errors['card.number'][0]) : false;
      errors['card.code'] ? (this.validation.cardCode = true, this.validationTxt.cardCode = errors['card.code'][0]) : false;
      errors['contact.firstName'] ? (this.validation.firstName = true, this.validationTxt.firstName = errors['contact.firstName'][0]) : false;
      errors['contact.lastName'] ? (this.validation.lastName = true, this.validationTxt.lastName = errors['contact.lastName'][0]) : false;
      errors['contact.phone'] ? (this.validation.phone = true, this.validationTxt.phone = errors['contact.phone'][0]) : false;
      errors[0] ? (this.validation.error = true, this.validationTxt.error = errors[0]) : false;
    },
  },

  mounted() {
    this.$store.dispatch('fetchCountries').then(() => {
      let countries = this._.toArray(this.$store.getters.getCountries);
      let filteredCountries = filterCountriesUaBel(countries)

      this.options = filteredCountries.countries;
    })
  },

  methods: {
    applyForm() {

      let validationItems = {
        card: this.card,
        month: this.month,
        year: this.year,
        cardCode: this.cardCode,
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.phone,
        selectedCountry: this.selectedCountry,
        city: this.city,
        street: this.street,
        state: this.state,
        zipCode: this.zipCode,
      }

      let card = {type: ['empty']},
          month = {type: ['numeric', 'empty']},
          year = {type: ['numeric', 'empty']},
          cardCode = {type: ['numeric', 'empty']},
          firstName = {type: ['empty']},
          lastName = {type: ['empty']},
          phone = {type: ['phone', 'empty']},
          selectedCountry = {type: ['empty']},
          city = {type: ['empty']},
          street = {type: ['empty']},
          state = {type: ['empty']},
          zipCode = {type: ['empty']}

      let validationOptions = {
        card: card,
        month: month,
        year: year,
        cardCode: cardCode,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        selectedCountry: selectedCountry,
        city: city,
        street: street,
        state: state,
        zipCode: zipCode,
      }

      let validate = validation(validationItems, validationOptions);
      this.validation = validate.validation;
      this.validationTranslate = validate.validationError;

      if (validate.isValidate && !this.checkCredit) {
        this.$emit('submitForm', validationItems)
      }
    },

    changeCountry(val) {
      if (val === '') {
        this.selectedCountry = ''
        this.selectedCountryObject = null
      } else {
        this.selectedCountry = val.label
        this.selectedCountryObject = val
        if (val.id === USA_COUNTRY_ID && this.regions.length === 0) {
          this.$store.dispatch('getRegionsByCountry', val.id).then(() => {
            this.regions = this._.toArray(this.$store.getters.getRegions);
          }).catch(error => this.createErrorLog(error));
        }
      }

    },

    changeRegion(val) {
        this.state = val?.code ? val.code : ''
    },

    checkCard(val) {
      if (isValid(val)) {
        this.checkCredit = false
        this.validationTranslate.card = ''
      } else {
        this.checkCredit = true
        this.validationTranslate.card = 'common_invalidCard'
      }
    },

    changeMonth(val) {
      if (val.length === 2) {
        this.$refs.refYear.$el.click()
      }
    },

    changeYear(val) {
      if (val.length === 2) {
        this.$refs.refCVV.$el.click()
      }
    },
  }
}
</script>

<style lang="scss">
@import "../../../../scss/colors";

.statistic-popup {
  &__price {
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 16px;
    color: $black;
  }

  .modal-component__inner {
    max-width: 784px;
  }

  .modal-component__header {
    margin-bottom: 5px;
  }

  .ui-no-valid-card {
    font-size: 13px;
    line-height: 13px;
    color: $orange;
  }
}
</style>
