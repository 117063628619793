<template>

  <AccordBlock>
    <template slot="header">
      Order Details
    </template>

    <template slot="body">

<!--      <div class="section-label mb-low">-->
<!--        Order Dimensions-->
<!--      </div>-->

      <template v-if="!isLabel">
        <div class="order-create__section-label section-label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_Packaging'])"></div>
          {{$t('fbm_Packaging.localization_value.value')}}
        </div>

        <div class="mb-3 fsz14 red-color-style">
          <div class="mb-2 red-color-style"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_userChoose'])"></div>
            {{$t('fbm_userChoose.localization_value.value')}}
          </div>
          {{activeUserType}}
        </div>
      </template>

      <div class="order-create__section-label section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_Dimentions'])"></div>
        {{$t('fbm_Dimentions.localization_value.value')}}
      </div>

      <Dimensions
              :FBM="FBM"
              :dimensions="FBM.data.AdminDimensions"
              :type="'imperial'"
              :isAdminManage="true"
      />

      <div class="order-create__section-label section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_UserDimentions'])"></div>
        {{$t('fbm_UserDimentions.localization_value.value')}}
      </div>

      <Dimensions
              :FBM="FBM"
              :dimensions="FBM.data.Dimensions"
              :type="'metric'"
              :adminWeight="true"
              :disabledFields="true"
      />


      <template v-if="!isWarehouse">
        <template v-for="(item, index) in proformProducts">
          <div class="order-create__title-horizontal"
               :key="index + '1'"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
						'product_Product',
						'common_DeleteProduct',
					])"></div>

            <TitleHorizontal
                    :value="$t('product_Product.localization_value.value') + ' #' + parseInt(index + 1)"
                    :rightBtn="true"
                    :rightBtnType="'delete'"
                    :rightBtnText="$t('common_DeleteProduct.localization_value.value')"
                    @rightBtnClick="$emit('removeProformProduct', index)"
            />
          </div>

          <ProductItemCreation
                  :key="index"
                  :product="item"
                  :serverError="false"
                  :visibleHSCode="FBM.data.visibleHSCode"
                  :canChangeHSCode="true"
                  :altVersion="true"
                  :nameForLabel="selectedCountry.id !== USA_COUNTRY_ID"
                  @changeCategory="({val, nameVal}) => $emit('changeCategory', {val, nameVal, item: proformProducts[index]})"
                  @changeProformSelectItem="$emit('changeProformSelectItem', item)"
          />
        </template>
      </template>

      <div class="order-create__section-label section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_Carrier'])"></div>
        {{$t('fbm_Carrier.localization_value.value')}}
        <TooltipTextHelperPlugin>
          <template slot="text"><TooltipBtn/></template>
          <template slot="title">{{$t('fbm_CarrierHelp.localization_value.value')}}</template>
          <template slot="paragraph">{{$t('fbm_CarrierHelpDesk.localization_value.value')}}</template>
        </TooltipTextHelperPlugin>
      </div>

<!--              :reloadKey="keyReload"-->
      <Shipping2
              v-if="FBM.data.shippingCompanies.length > 0"
              :FBM="FBM"
              :shippingCompanyData="FBM.data.shippingCompanies"
              :isAdminManage="true"
              @reCreateLabel="$emit('reCreateLabel')"
              @reload="$emit('reload')"
      />
<!--              :isDestinationToUSA="FBM.getTrsnAddressCountry().id === USA_COUNTRY_ID"-->


      <template v-if="!isLabel">
        <div class="order-create__section-label section-label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_Packaging'])"></div>
          {{$t('fbm_Packaging.localization_value.value')}}
        </div>
        <AdminPackingPrices
                :FBM="FBM"
        />
      </template>




      <div class="custom-row">
        <div class="custom-col">

          <template v-if="!(globalCompany && isConsolidation)">
            <div class="order-create__section-label section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                'fbm_Insurance',
                'fbm_InsuranceToltip'
              ])"></div>
              {{$t('fbm_Insurance.localization_value.value')}}
              <v-popover
                      class="site-tooltip"
                      offset="5"
                      placement="top"
                      trigger="hover"
              >

                <TooltipBtn/>

                <template slot="popover">
                  <p><b>{{$t('fbm_Insurance.localization_value.value')}}</b></p>
                  <p>{{$t('fbm_InsuranceToltip.localization_value.value')}}</p>
                </template>
              </v-popover>
            </div>

            <div class="section-label">
              <InsuranceBlock
                      :FBM="FBM"
                      :checkboxState="false"
              />
            </div>
          </template>



          <!--<div class="order-create__section-label section-label"-->
               <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
            <!--<div class="admin-edit" @click="editTranslate(['fbm_Managering'])"></div>-->
            <!--{{$t('fbm_Managering.localization_value.value')}}-->
          <!--</div>-->

          <!--<div class="order-create__product custom-row">-->
            <!--<div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100"-->
                 <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
              <!--<div class="admin-edit" @click="editTranslate(['fbm_LotNumber'])"></div>-->
              <!--<DefaultInput-->
                      <!--:label="$t('fbm_LotNumber.localization_value.value')"-->
                      <!--:type="'text'"-->
                      <!--v-bind:class="{'ui-no-valid': FBM.validation.lotNumber}"-->
                      <!--:errorTxt="$t(`${FBM.validationTranslate.lotNumber}.localization_value.value`)"-->
                      <!--:error="FBM.validation.lotNumber"-->
                      <!--v-model="FBM.data.lotNumber"-->
              <!--/>-->
            <!--</div>-->
          <!--</div>-->

          <!--<div class="custom-row">-->
            <!--<div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100">-->
              <!--<FbmStatuses-->
                      <!--:FBM="FBM"-->
              <!--/>-->
            <!--</div>-->
          <!--</div>-->

          <div style="margin-top: 40px;">
            <EngravingFile
                v-if="isWarehouse && !isWarehouseCambridge"
                :FBM="FBM"
            />
          </div>

          <div class="custom-row">
            <VatNumberBlock
                    :FBM="FBM"
                    :isAdminManage="true"
                    :fileWidthClass="'custom-col--50'"
            />
          </div>

          <div class="row">
            <TaxBlock
                :FBM="FBM"
            />
          </div>

        </div>
      </div>


    </template>
  </AccordBlock>
</template>

<script>
  import AccordBlock from "../../../../../../coreComponents/AccordBlock/AccordBlock";
  // import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  // import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  // import RadioLabelPicture from "../../../../../../UI/radiobuttons/RadioLabelPicture/RadioLabelPicture";
  // import RadioDefault from "../../../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import TooltipBtn from "../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  // import MainButton from "../../../../../../../../UI/buttons/MainButton/MainButton";
  // import InputSum from "../../../../../../UI/inputs/InputSum/InputSum";
  // import Shipping from "../../chunks/Shipping/Shipping";
  import {FBMMixins} from "../../../../../../../mixins/FBMMixins/FBMMixins";
  import InsuranceBlock from "../../chunks/InsuranceBlock/InsuranceBlock";
  import Dimensions from "../../chunks/Dimensions/Dimensions";
  import AdminPackingPrices from "../AdminPackingPrices/AdminPackingPrices";
  import TitleHorizontal from "../../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
  import ProductItemCreation from "../../../../../../coreComponents/ProductItemCreation/ProductItemCreation";
  // import FbmStatuses from "../FbmStatuses/FbmStatuses";
  import {USA_COUNTRY_ID} from "../../../../../../../staticData/staticVariables";
  import VatNumberBlock from "../../chunks/VatNumberBlock/VatNumberBlock";
  import TooltipTextHelperPlugin from "@/components/coreComponents/TooltipTextHelperPlugin/TooltipTextHelperPlugin";
  import EngravingFile
    from "@/components/modules/OrdersFBMModule/components/components/chunks/EngravingFile/EngravingFile";
  import Shipping2 from "@/components/modules/OrdersFBMModule/components/components/chunks/Shipping2/Shipping2";
  import TaxBlock from "@/components/modules/OrdersFBMModule/components/components/chunks/TaxBlock/TaxBlock";

  export default {
    name: "OrderDetails",
    components: {
      TaxBlock,
      Shipping2,
      EngravingFile,
      TooltipTextHelperPlugin,
      VatNumberBlock,
      // FbmStatuses,
      ProductItemCreation,
      TitleHorizontal,
      AdminPackingPrices,
      Dimensions,
      InsuranceBlock,
      // Shipping,
      // InputSum,
      // MainButton,
      TooltipBtn,
      // DefaultCheckbox,
      // RadioDefault,
      // RadioLabelPicture,
      // DefaultSelect,
      // DefaultInput,
      AccordBlock
    },

    mixins: [FBMMixins],

    props: {
      FBM: Object,
      proformProducts: Array,
      keyReload: Number,
    },

    watch: {
      /**
       * If load user and not admin or order type Edit
       */
      // orderUser(newVal) {
      //   if(newVal !== '' && (!this.isAdmin || this.$route.params.id)){
      //     this.getShippingData()
      //   }
      // },

      // keyReload(){
      //   this.checkShippingCompany()
      // },
      //

      // receivedAt() {
        // this.checkShippingCompany(this.isAdmin).then(() => {
        //   this.FBM.data.shippingCompanies.forEach(company => {
        //     company.shipping_methods.forEach(method => {
        //       if(this.FBM.data.shippingPartner.id === method.id){
        //         this.FBM.data.shippingPartner = method
        //       }
        //     })
        //   })
        //   this.reload()
        // })
      // },

      /**
       * If change or choose Country
       */
      selectedCountry() {
        this.getShippingData().then(() => {
          if (this.currentPersonalContactName && this.selectedCountry && this.FBM.data.useEasyPost) {
            this.getShippingPrices(true)
          }
        })
      },
    },

    computed: {
      orderUser(){
        return this.FBM.data.User.userId
      },

      receivedAt(){
        return this.FBM.data.receivedAt
      },

      activeUserType() {
        return this.FBM.data.packagingType?.translationStorage?.en?.name
      },
    },

    data() {
      return {
        sum01: '',
        sum02: '',
        sum03: '',
        sum04: '',
        sum05: '0',
        sum001: '',
        sum002: '',
        options: [{}],
        shippingPartner: 1,
        atm: false,

        USA_COUNTRY_ID: USA_COUNTRY_ID,

        // reloadKey: -1,
      }
    },

    mounted() {
      // if(Object.keys(this.FBM.data.shippingPartner).length > 0){
        this.checkShippingCompany()
      // }
    },

    methods: {
      changeShippingPartner(val){
        this.shippingPartner = val
      },

      // reload() {
      //   this.reloadKey = Math.random()
      // },
    }
  }
</script>

<style scoped lang="scss">

  .toggle-block.small-size .toggle-block__type{
    margin-right: 0px;
  }

  .toggle-block.small-size .toggle-block__details {
    max-width: 100%;
    min-height: 310px;
    width: 100%;
  }

  @media (max-width: 1120px){
    .toggle-block__details {
      background: #F8F4EE;
      border: 1px solid #BBAD9C;
    }
  }

  @media (max-width: 992px){
    .toggle-block__details{
      margin-top: 20px;
    }
  }

  @media (max-width: 1200px){
    .toggle-block.small-size .toggle-block__details {
      min-height: auto;
    }
  }



  .min-w-140{
    min-width: 140px;
  }

  .fsz11{
    font-size: 11px;
    color: #232323;
  }

  .red-color-style {
    color: #ff0c0c;
  }

</style>
